// vendors
// components
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '../components/Accordion';
import UnderlinedButton from '../components/Button/UnderlinedButton';
import InView from '../components/InView/InVew';
import Layout from '../components/Layout/Layout';
import AprilLogotype from '../components/Logo/AprilLogotype';
import AprilPictogram from '../components/Logo/AprilPictogram';
import AubergeLogotype from '../components/Logo/AubergeLogotype';
import PortableMenuText from '../components/PortableMenuText';
import Text from '../components/Text';
import Title from '../components/Title';
import VideoSection from '../components/VideoSection/VideoSection';
import petal1 from '../images/petal1.svg';
import petal2 from '../images/petal2.svg';
import petal3 from '../images/petal3.svg';
// Videos
import mp4Desktop from '../videos/april700k.mp4';
// views
import RestaurantCarousel from '../views/RestaurantCarousel/RestaurantCarousel';
import RestaurantPageAbout from '../views/RestaurantPageAbout/RestaurantPageAbout';
import PopupComponent from '../components/PopupComponent/PopupComponent';
import { convertContentToString } from '../libraries/ContentToString';
import CookieConsentCSS from '../components/CookieConsent/CookieConsentCSS';
import CookieConsent, {
  doNecessaryCookieAuthorized,
  doAnalyticCookieAuthorized,
  doAdsCookieAuthorized,
} from '../components/CookieConsent/CookieConsent';

const alternativesLanguages = [
  {
    locale: 'en',
    href: '/en/restaurant',
  },
];


function RestaurantPage({ data }) {
  
  const popupMessage = convertContentToString(data.businessCloseMessage?.portableText);

  return (
    <Layout
      className="pb-4 bg-paleOrange-50"
      alternativesLanguages={alternativesLanguages}
    >
      <CookieConsentCSS></CookieConsentCSS>

      <CookieConsent langcode="fr"></CookieConsent>
      {popupMessage.length > 10 && <PopupComponent message={popupMessage} />}

      <div className="text-center">
        <p className="inline-block px-12 mt-32 mb-16 max-w-[32rem] md:mt-16">
          <span className="sr-only">Auberge sur Mer</span>

          <AubergeLogotype className="w-full" role="presentation" />
        </p>
      </div>

      <header className="container flex mx-auto">
        <div className="flex relative">
          <div className="grid flex-1 grid-cols-1 grid-rows-3 w-1/2">
            <h1 className="fluid-w-[8/18]">
              <span className="sr-only">April - Bisto de saison</span>

              <AprilLogotype />
            </h1>

            <InView className="mr-4 max-w-md">
              <Title level={3} className="!mb-0">
                Cuisine inspirée par les produits locaux et de saison
              </Title>

              <UnderlinedButton href="#menu">Menus ↓</UnderlinedButton>
            </InView>
          </div>

          <StaticImage
            className="flex-1 self-start w-1/2"
            src="../images/restaurant/April-Bistro-01.jpg"
            loading="eager"
            alt={``}
          />

          <img
            src={petal1}
            role="presentation"
            alt=""
            className="absolute top-3/4 left-[40%] fluid-w-[1.625/5]"
          />

          <img
            src={petal2}
            role="presentation"
            alt=""
            className="absolute top-[20%] left-0 fluid-w-[1.25/4]"
          />

          <img
            src={petal3}
            role="presentation"
            alt=""
            className="absolute top-0 left-[40%] fluid-w-[1.625/5]"
          />
        </div>
      </header>

      <section
        id="menu"
        className="container gap-x-44 mx-auto lg:flex fluid-my-[0/8]"
      >
        <InView className="my-8 text-center lg:text-left">
          <Title level={2}>Menu</Title>
        </InView>

        <InView className="grow">
          <Accordion>
            <AccordionItem>
              <Title level={3} className="!mb-0">
                <AccordionButton>Déjeuners</AccordionButton>
              </Title>

              <AccordionPanel>
                <PortableMenuText
                  value={data.breakfastMenu?.portableText || []}
                />
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <Title level={3} className="!mb-0">
                <AccordionButton>Snacks</AccordionButton>
              </Title>

              <AccordionPanel>
                <PortableMenuText value={data.snackMenu?.portableText || []} />
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <Title level={3} className="!mb-0">
                <AccordionButton>Souper</AccordionButton>
              </Title>

              <AccordionPanel>
                <PortableMenuText value={data.dinerMenu?.portableText || []} />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </InView>
      </section>

      <section className="fluid-my-[5/14]">
        <InView>
          <Title
            level={3}
            className="container mx-auto text-center fluid-my-[2/6]"
          >
            Nos plats en image
          </Title>

          <RestaurantCarousel />
        </InView>
      </section>

      <section id="a-propos" className="container mx-auto fluid-my-[5/14]">
        <InView className="fluid-my-[8/14]">
          <AprilPictogram className="mx-auto w-20 fluid-my-[2/3]" />

          <p className="mx-auto max-w-6xl font-title text-center fluid-text-[1.25/3]">
            Situé dans un lieu enchanteur avec vue sur le fleuve,
            le&nbsp;restaurant April est au cœur de l’un des plus beaux villages
            du&nbsp;Québec
          </p>
        </InView>

        <RestaurantPageAbout
          teamContent={
            <Text>
              <Title level={3}>L&apos;équipe</Title>

              <p>
                Notre chef et son équipe, vous charmera avec sa cuisine
                savoureuse, inspirée des produits de saison, permettant ainsi
                une immersion dans notre belle région.
              </p>
            </Text>
          }
          viewContent={
            <Text>
              <Title level={3}>La vue</Title>

              <p>
                Notre ravissante salle à manger aux multiples fenêtres donne
                directement sur l’immensité du fleuve St-Laurent et ses
                incomparables couchers de soleil. Un paysage aux charmes
                exclusifs, comme seule la région du Bas-St-Laurent peut offrir!
              </p>
            </Text>
          }
        />
      </section>

      <section
        id="horaires"
        className="container mx-auto text-center lg:flex lg:text-left fluid-my-[5/14]"
      >
        <InView className="flex-1">
          <Title className="!mb-16" level={2}>
            Horaires
          </Title>
        </InView>

        <InView className="flex-1 space-y-16 opening-hour">

          <PortableMenuText value={data.openingHours?.portableText || []} />


        </InView>
      </section>

      <InView className="container mx-auto fluid-my-[8/20]">
        <VideoSection desktopMp4={mp4Desktop} />
      </InView>
    </Layout>
  );
}

export default RestaurantPage;

RestaurantPage.propTypes = {
  data: PropTypes.shape({
    breakfastMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    dinerMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    snackMenu: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    
    businessCloseMessage: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
    privacyRules: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
    cookieRules: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),
    openingHours: PropTypes.shape({
      portableText: PropTypes.arrayOf(PropTypes.shape()),
    }),    
  }).isRequired,
};


export const query = graphql`
  query {
    breakfastMenu: sanityBreakfastMenu(_id: { eq: "breakfastMenu" }) {
      portableText: _rawFr(resolveReferences: { maxDepth: 10 })
    }
    dinerMenu: sanityDinerMenu(_id: { eq: "dinerMenu" }) {
      portableText: _rawFr(resolveReferences: { maxDepth: 10 })
    }
    snackMenu: sanitySnackMenu(_id: { eq: "snackMenu" }) {
      portableText: _rawFr(resolveReferences: { maxDepth: 10 })
    }
    businessCloseMessage: sanityBusinessCloseMessage(_id: { eq: "businessCloseMessage" }) {
      portableText: _rawFr(resolveReferences: { maxDepth: 10 })
    }
    privacyRules: sanityPrivacyRules(_id: { eq: "privacyRules" }) {
      portableText: _rawFr(resolveReferences: { maxDepth: 10 })
    }
    cookeyRules: sanityCookieRules(_id: { eq: "cookieRules" }) {
      portableText: _rawFr(resolveReferences: { maxDepth: 10 })
    }
    openingHours: sanityOpeningHours(_id: { eq: "openingHours" }) {
      portableText: _rawFr(resolveReferences: { maxDepth: 10 })
    }
  }
`;


